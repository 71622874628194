import PropTypes from "prop-types";
import React from "react";

export const StyleTwoTone = ({ color = "black", className }) => {
  return (
    <svg
      className={`style-two-tone ${className}`}
      fill="none"
      height="35"
      viewBox="0 0 34 35"
      width="34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.9804 11.6657C18.5369 11.6657 19.8105 10.3533 19.8105 8.74928C19.8105 7.14525 18.5369 5.83286 16.9804 5.83286C15.4239 5.83286 14.1504 7.14525 14.1504 8.74928C14.1504 10.3533 15.4239 11.6657 16.9804 11.6657ZM16.9804 14.5821C15.4239 14.5821 14.1504 15.8945 14.1504 17.4985C14.1504 19.1026 15.4239 20.415 16.9804 20.415C18.5369 20.415 19.8105 19.1026 19.8105 17.4985C19.8105 15.8945 18.5369 14.5821 16.9804 14.5821ZM16.9804 23.3314C15.4239 23.3314 14.1504 24.6438 14.1504 26.2478C14.1504 27.8518 15.4239 29.1642 16.9804 29.1642C18.5369 29.1642 19.8105 27.8518 19.8105 26.2478C19.8105 24.6438 18.5369 23.3314 16.9804 23.3314Z"
        fill={color}
      />
    </svg>
  );
};

StyleTwoTone.propTypes = {
  color: PropTypes.string,
};