import { STORAGE_KEY, getItemFromStorage, setItemInStorage } from "@/utils";

function setGLCopyFlagInStorage(flag: boolean) {
  setItemInStorage(STORAGE_KEY["GL_COPY_FLAG"], flag);
}

function getGLCopyFlagInStorage(): boolean {
  return getItemFromStorage(STORAGE_KEY["GL_COPY_FLAG"]);
}

export { setGLCopyFlagInStorage, getGLCopyFlagInStorage };
