import {
  Badges,
  CardContainer,
  ConfigurationStatusComponent,
} from "@/components/common";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import Image from "next/image";
import {
  activeInterstial,
  completeInterstial,
  rightRotateYellow,
  IconEdit,
  expandDownIcon,
} from "public/assets";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import "./InProgress.scss";
import Link from "next/link";
import { ProgressStatus } from "@/components/data/form-data";
import { useRouter } from "next/navigation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  CRT_CONFIG_STAGE_MAPPING,
  CRT_ENTITY,
  DASHBOARD_MODEL_KEY,
  FIN_ORG_SUB_STEPS,
  GL_CONFIG_STAGE_MAPPING,
  GL_ENTITY,
  IC_CONFIG_STAGE_MAPPING,
  IC_ENTITY,
  NEW_IC_ENTITY_ID,
  SUMMARY_LANDING_PAGE_TYPES,
  SUMMARY_LANDING_SCREEN_STATUS,
  WORKFLOW_STATUS,
  ENTITLEMENT_STATUS,
  removeSplChars,
} from "@/utils";

import {
  getOrganizations,
  getEnvIsDisable,
  getOrgID,
  getSelectedOrg,
} from "@/store";
import { useSelector } from "react-redux";
import {
  IC_LIST_CONFIG,
  GL_LIST_CONFIG,
  CRT_LIST_CONFIG,
  ListConfigType,
} from "../completed-page/config";
import { getEntitlement } from "@/store";
import { API_ROUTE_CONFIGURATION, ApiClient } from "@/api-config";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import {
  getCoreModelEndpoint,
  getWorkflowGeneric,
} from "@/api-config/api-service";
interface InProgressProps {
  data: ProgressStatus[];
  progressIndex?: number;
  resumeLink?: string;
  title: SummaryLandingPageTypes;
  leftBadgeLabel: string;
  rightBadgeLabel: string;
  headingText: string;
  paragraphText: string;
}

// Define the valid keys of the ROUTE_MAP
type SummaryLandingPageTypes =
  | "Institutional Configuration"
  | "General Ledger Setup"
  | "Customer relationship types"
  | "Transaction Code Setup";

// Define the ROUTE_MAP with specific keys and values
const ROUTE_MAP: { [key in SummaryLandingPageTypes]: string } = {
  "Institutional Configuration":
    ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM,
  "General Ledger Setup": ROUTE_BASE_URL.GENERAL_LEDGER_FORM,
  "Customer relationship types": ROUTE_BASE_URL.CUSTOMER_RELATIONSHIP_TYPE,
  "Transaction Code Setup": ROUTE_BASE_URL.TRANS_CODE,
};

const API_MAP = {
  [SUMMARY_LANDING_PAGE_TYPES.ic]: [
    API_ROUTE_CONFIGURATION.accountGroup,
    API_ROUTE_CONFIGURATION.assetCatalogue,
    API_ROUTE_CONFIGURATION.accountType,
    API_ROUTE_CONFIGURATION.FICalendar,
    API_ROUTE_CONFIGURATION.financialOrg,
    API_ROUTE_CONFIGURATION.bankParams,
  ],
  [SUMMARY_LANDING_PAGE_TYPES.gl]: [
    API_ROUTE_CONFIGURATION.accountingSegments,
    API_ROUTE_CONFIGURATION.accounts,
    API_ROUTE_CONFIGURATION.systemAccounts,
    API_ROUTE_CONFIGURATION.sets,
    API_ROUTE_CONFIGURATION.positions,
  ],
  [SUMMARY_LANDING_PAGE_TYPES.crt]: [API_ROUTE_CONFIGURATION.crtConfig],
  [SUMMARY_LANDING_PAGE_TYPES.tc]: [API_ROUTE_CONFIGURATION.tcConfig],
};

const STAGE_MAP = {
  [SUMMARY_LANDING_PAGE_TYPES.ic]: IC_CONFIG_STAGE_MAPPING,
  [SUMMARY_LANDING_PAGE_TYPES.gl]: GL_CONFIG_STAGE_MAPPING,
  [SUMMARY_LANDING_PAGE_TYPES.crt]: CRT_CONFIG_STAGE_MAPPING,
};

const DATA_MAP: Record<string, Record<string, any[]>> = {
  [SUMMARY_LANDING_PAGE_TYPES.ic]: {
    [IC_ENTITY.account_group]: [],
    [IC_ENTITY.asset_catalog]: [],
    [IC_ENTITY.account_type]: [],
    [IC_ENTITY.financial_calendar]: [],
    [IC_ENTITY.financial_organization]: [],
    [IC_ENTITY.bank_parameters]: [],
  },
  [SUMMARY_LANDING_PAGE_TYPES.gl]: {
    [GL_ENTITY.accounting_segments]: [],
    [GL_ENTITY.accounts]: [],
    [GL_ENTITY.system_accounts]: [],
    [GL_ENTITY.sets]: [],
    [GL_ENTITY.positions]: [],
  },
  [SUMMARY_LANDING_PAGE_TYPES.crt]: {
    [CRT_ENTITY.party_relationships]: [],
    [CRT_ENTITY.general_setup]: [],
  },
};

const InProgress = (props: InProgressProps) => {
  const { entityWorkflowData } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const data = useMemo(() => {
    if (entityWorkflowData) {
      return props.data.map((entity) => {
        const entityFromConfig = entityWorkflowData.find(
          (workflowEntity) => workflowEntity.stage === entity.id
        );
        return {
          ...entity,
          href: `${entity.href}?entityId=${
            entityFromConfig?.modelKey
              ? encodeURIComponent(entityFromConfig?.modelKey) //some entityId is having UTF-8 character so encoding it
              : NEW_IC_ENTITY_ID
          }`,
        };
      });
    }
    return props.data;
  }, [entityWorkflowData, props.data]);
  const {
    progressIndex = 0,
    resumeLink = "",
    title,
    leftBadgeLabel,
    rightBadgeLabel,
    headingText,
    paragraphText,
  } = props;
  let completedItems = data;
  let inProgressItems;
  let notCompletedItems = data;
  if (progressIndex === 0) {
    completedItems = [];
    inProgressItems = null;
    notCompletedItems = data?.slice(progressIndex, data.length);
  } else {
    completedItems = data && data?.slice(0, progressIndex - 1);
    inProgressItems = data && data[progressIndex - 1];
    notCompletedItems = data?.slice(progressIndex, data.length);
  }

  const [entityListData, setConfigList] = useState(DATA_MAP[props.title]);
  const [assestClass, setAssetClass] = useState([]);
  const [glCategory, setGLCategory] = useState([]);
  const [accountGroup, setAccountGroup] = useState([]);
  const [accountSeg, setAccountSeg] = useState([]);
  const [timezone, setTimezone] = useState([]);
  const selectedOrg = useSelector(getSelectedOrg);

  const router = useRouter();
  const envIsDisabled = useSelector(getEnvIsDisable);

  const listConfig = useMemo<any>(() => {
    switch (props.title) {
      case SUMMARY_LANDING_PAGE_TYPES.ic:
        return IC_LIST_CONFIG;
      case SUMMARY_LANDING_PAGE_TYPES.gl:
        return GL_LIST_CONFIG;
      case SUMMARY_LANDING_PAGE_TYPES.crt:
        return CRT_LIST_CONFIG;
    }
  }, [props.title]);

  const routeConfigPath = useMemo<any>(() => {
    switch (props.title) {
      case SUMMARY_LANDING_PAGE_TYPES.ic:
        return IC_CONFIG_STAGE_MAPPING;
      case SUMMARY_LANDING_PAGE_TYPES.gl:
        return GL_CONFIG_STAGE_MAPPING;
      case SUMMARY_LANDING_PAGE_TYPES.crt:
        return CRT_CONFIG_STAGE_MAPPING;
    }
  }, [props.title]);

  const onResume = () => {
    router.push(resumeLink);
  };
  const entitlement = useSelector(getEntitlement);
  let entitlementType: "gl" | "ic" | "tc" | "crt" = "ic";
  switch (title) {
    case SUMMARY_LANDING_PAGE_TYPES.gl:
      entitlementType = "gl";
      break;
    case SUMMARY_LANDING_PAGE_TYPES.ic:
      entitlementType = "ic";
      break;
    case SUMMARY_LANDING_PAGE_TYPES.crt:
      entitlementType = "crt";
      break;
    case "Transaction Code Setup":
      entitlementType = "tc";
      break;
  }

  // Generic function to generate list data for different entities
  const _generateData = useCallback(
    (entityType: IC_ENTITY | GL_ENTITY | CRT_ENTITY) => {
      const entityConfig = listConfig[entityType] as ListConfigType;
      const entityApiData = entityListData[entityType];

      // Delimiter for composite keyed entities
      const [mainPk, secPk] = entityConfig.pk.split("~");
      // Delimiter for bank paramaters
      const nameKey = entityConfig.nameKey.split(".");
      const dataWithStatus = entityApiData.reduce<{
        completed: any[];
        inProgress: any[];
      }>(
        (acc, entityData) => {
          // Using ~ delimiter for composite keys
          const identifier = secPk
            ? `${entityData[mainPk]}~${entityData[secPk]}`
            : `${entityData[mainPk]}`; // converting to string
          const configForEntity = entityWorkflowData?.find(
            (workflowData) =>
              workflowData.modelKey === identifier &&
              workflowData.stage === entityType
          );
          const url = `${ROUTE_MAP[title]}?entityId=${encodeURIComponent(identifier)}&entityType=${entityType}`; //some entityId is having UTF-8 character so encoding it
          // If nameKey is using a delimiter (Bank params), Get Bank param name
          const name = nameKey[1]
            ? entityListData[IC_ENTITY.financial_organization].find(
                (org: any) => org._Id === entityData._Id
              )?.[nameKey[1]]
            : entityData[nameKey[0]];

          if (
            entityType === IC_ENTITY.financial_organization
              ? configForEntity?.status.includes(
                  FIN_ORG_SUB_STEPS.fin_org_rules
                )
              : configForEntity?.status.includes(WORKFLOW_STATUS.completed)
          ) {
            if (
              entityType !== IC_ENTITY.asset_catalog &&
              entityType !== GL_ENTITY.accounts
            ) {
              acc["completed"].push({
                ...entityData,
                url,
                name,
              });
            } else if (entityType === IC_ENTITY.asset_catalog) {
              //Show Asset class names
              acc["completed"].push({
                ...entityData,
                url,
                name,
                assetClass: assestClass[entityData.assetClass - 1] ?? "",
              });
            } else if (entityType === GL_ENTITY.accounts) {
              acc["completed"].push({
                ...entityData,
                url,
                name,
                acctGroup: accountGroup[entityData?.acctGroup - 1] ?? "",
              });
            } else if (entityType === GL_ENTITY.positions) {
              acc["completed"].push({
                ...entityData,
                url,
                name,
                acctGroup: accountGroup[entityData?.acctGroup - 1] ?? "",
                timezone: timezone?.find(
                  (item: any) => item.tmZoneCode === entityData.tmZoneCode
                ),
                acctgSeg: accountSeg
                  .filter((item: any) =>
                    entityData.acctgSeg.hasOwnProperty(item.key)
                  )
                  .map((item: any) => ({
                    ...item,
                    value: item.value.filter((v: any) =>
                      v.includes(entityData.acctgSeg[item.key])
                    ),
                  })),
                glCat: glCategory[entityData?.glCat - 1] ?? "",
                assetClass: assestClass[entityData.assetClass - 1] ?? "",
              });
            }
          } else {
            acc["inProgress"].push({
              ...entityData,
              name,
              url,
              ...(entityType === GL_ENTITY.positions && {
                acctGroup: accountGroup[entityData?.acctGroup - 1] ?? "",
                timezone: timezone?.find(
                  (item: any) => item.tmZoneCode === entityData.tmZoneCode
                ),
                acctgSeg: accountSeg
                  .filter((item: any) =>
                    entityData.acctgSeg.hasOwnProperty(item.key)
                  )
                  .map((item: any) => ({
                    ...item,
                    value: item.value.filter((v: any) =>
                      v.includes(entityData.acctgSeg[item.key])
                    ),
                  })),
                glCat: glCategory[entityData?.glCat - 1] ?? "",
                assetClass: assestClass[entityData.assetClass - 1] ?? "",
              }),
            });
          }
          return acc;
        },
        { completed: [], inProgress: [] }
      );

      return {
        ...entityConfig,
        //For System Accounts in General Ledger Setup if there are two entries in table then disable the "Add New" button
        disableBtn:
          dataWithStatus.completed.length + dataWithStatus.inProgress.length >=
            2 && entityConfig.title === "System Accounts",
        showAddBtn: envIsDisabled ? false : entityConfig.showAddBtn,
        entityType,
        completedData: dataWithStatus.completed,
        inProgressData: dataWithStatus.inProgress,
      };
    },

    [entityWorkflowData, entityListData, props.title, listConfig]
  );

  useEffect(() => {
    (async () => {
      const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
      const res = await Promise.all(
        API_MAP[props.title].map((url) => api.get(url))
      );
      switch (props.title) {
        case SUMMARY_LANDING_PAGE_TYPES.ic:
          //Get assest class data for asset catelog
          const [assetClassData] = await Promise.all([
            api.options(API_ROUTE_CONFIGURATION.assetCatalogue),
          ]).then((data) => data);
          const { assetClass } = assetClassData.actions.POST.properties;

          setConfigList({
            [IC_ENTITY.account_group]: res[0].data,
            [IC_ENTITY.asset_catalog]: res[1].data,
            [IC_ENTITY.account_type]: res[2].data,
            [IC_ENTITY.financial_calendar]: res[3].data,
            [IC_ENTITY.financial_organization]: res[4].data,
            [IC_ENTITY.bank_parameters]: res[5].data,
          });
          setAssetClass(assetClass.enumNames);
          break;
        case SUMMARY_LANDING_PAGE_TYPES.gl:
          const [accountGroupData, timeZoneData, assetClData] =
            await Promise.all([
              api.get(API_ROUTE_CONFIGURATION.accountGroup),
              api.get(API_ROUTE_CONFIGURATION.timeZone),
              api.options(API_ROUTE_CONFIGURATION.assetCatalogue),
            ]).then((data) => data);
          const glCatData = await api.options(
            API_ROUTE_CONFIGURATION.positions
          );
          const { glCat } = glCatData.actions.POST.properties;
          const assetData = assetClData.actions.POST.properties;
          const accountGroup = accountGroupData.data.map(
            (acc: any) => `${acc.acctGroup} - ${acc.desc}`
          );
          const timeZone = timeZoneData.data.map(
            ({ tmZone, tmZoneCode }: any) => ({ tmZone, tmZoneCode })
          );
          const acctgSeg = res[0].data.map((item: any) => ({
            key: item.acctgSeg,
            value: item.domainVals?.map(
              (val: any) => `${val.acctgSegVal} - ${val.acctgSegDesc}`
            ),
          }));

          setConfigList({
            [GL_ENTITY.accounting_segments]: res[0].data,
            [GL_ENTITY.accounts]: res[1].data,
            [GL_ENTITY.system_accounts]: res[2].data,
            [GL_ENTITY.sets]: res[3].data,
            [GL_ENTITY.positions]: res[4].data,
          });
          setAccountGroup(accountGroup);
          setTimezone(timeZone);
          setGLCategory(glCat.enumNames);
          setAccountSeg(acctgSeg);
          setAssetClass(assetData?.assetClass?.enumNames);
          break;
        case SUMMARY_LANDING_PAGE_TYPES.crt:
          setConfigList({
            [CRT_ENTITY.general_setup]: res[0].data,
            [CRT_ENTITY.party_relationships]: res[1].data,
          });
          break;
      }
    })();
  }, []);

  useEffect(() => {
    if (props.title !== SUMMARY_LANDING_PAGE_TYPES.crt) {
      (async () => {
        const res = await getWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.dashboard,
          key: DASHBOARD_MODEL_KEY.foundationalSetup,
        });

        let cPhase = selectedOrg.phase ?? "";
        let nextStage = "";
        switch (props.title) {
          case SUMMARY_LANDING_PAGE_TYPES.ic:
            nextStage = "general_ledger";
            break;
          case SUMMARY_LANDING_PAGE_TYPES.gl:
            nextStage = "transaction_codes";
            break;
        }
        const nextStageStatus = res.filter(
          (item: any) => item.stage === nextStage
        );
      })();
    }
  }, [props.title, selectedOrg]);

  const configList = useMemo(() => {
    const allEntitiesData: ReturnType<typeof _generateData>[] = [];
    Object.keys(entityListData).forEach((entityType) => {
      const generatedData = _generateData(entityType as IC_ENTITY | GL_ENTITY);
      allEntitiesData.push(generatedData);
    });
    return allEntitiesData;
  }, [entityListData, _generateData]);

  return (
    <>
      <ConfigurationStatusComponent
        title={title}
        status={SUMMARY_LANDING_SCREEN_STATUS.in_progress}
        leftBadgeLabel={leftBadgeLabel}
        leftBadgeStatus={SUMMARY_LANDING_SCREEN_STATUS.completed}
        rightBadgeLabel={rightBadgeLabel}
        rightBadgeStatus={SUMMARY_LANDING_SCREEN_STATUS.not_started}
      />
      <CardContainer
        customClass="content-container"
        styleProps={{ marginTop: 0 }}
      >
        <Flex
          align="center"
          justifyContent="space-between"
          className="content-title"
        >
          <div className="content-complete-status">
            <Text>
              <span>{completedItems && completedItems?.length}</span>
              {` of `}
              <span>{data.length}</span> complete
            </Text>
          </div>
          <div className="content-title-text">
            <Text as="h3">{headingText}</Text>
            <Text>{paragraphText}</Text>
          </div>
          {progressIndex < 7 && (
            <Button
              onClick={onResume}
              className="app-btn-reg-secondary resume-button"
              isDisabled={
                entitlement[entitlementType] === ENTITLEMENT_STATUS.noAccess
              }
            >
              Resume <ChevronRightIcon w={10} h={10} />
            </Button>
          )}
        </Flex>
        <Divider w={"auto"} className="content-divider" />
        {completedItems &&
          completedItems?.length > 0 &&
          completedItems?.map((item, index) => (
            <Box key={index} marginRight={26}>
              <CardContainer customClass="progress-item">
                <div className="content">
                  {configList[index]?.inProgressData?.length > 0 &&
                  !configList[index]?.completedData?.length ? (
                    <Image
                      className="check-img"
                      src={rightRotateYellow}
                      alt="check"
                    />
                  ) : (
                    ""
                  )}
                  {configList[index]?.completedData?.length > 0 ? (
                    <Image
                      className="check-img"
                      src={completeInterstial}
                      alt="check"
                    />
                  ) : (
                    ""
                  )}
                  <div className="text-content">
                    <Text as={"h4"}>{item.title}</Text>
                    <Text>{item.text}</Text>
                    {configList[index]?.completedData?.length > 0 &&
                      configList[index]?.completedData.map(
                        (data: any, listIndex: number) => (
                          <div className="record-row" key={listIndex}>
                            <Image
                              className="check-img"
                              src={completeInterstial}
                              alt="check"
                            />
                            {data.name}
                            <Link href={data.url} className="edit-wrapper">
                              <Image
                                className="edit-icon"
                                src={IconEdit}
                                alt="Edit icon"
                              />
                            </Link>
                          </div>
                        )
                      )}
                    {configList[index]?.inProgressData?.length > 0 &&
                      configList[index]?.inProgressData.map(
                        (data: any, listIndex: number) => (
                          <div className="record-row" key={listIndex}>
                            <Image
                              className="check-img"
                              src={rightRotateYellow}
                              alt="check"
                            />
                            {data.name}
                            <Link href={data.url} className="edit-wrapper">
                              <Image
                                className="edit-icon"
                                src={IconEdit}
                                alt="Edit icon"
                              />
                            </Link>
                          </div>
                        )
                      )}
                    {configList[index]?.completedData?.length > 0 && (
                      <Box mt={5}>
                        <a
                          href={`${routeConfigPath[configList[index]?.entityType]}?entityId=${NEW_IC_ENTITY_ID}&entityType=${configList[index]?.entityType}`}
                          className="app-btn-link"
                        >
                          + Add another{" "}
                          {removeSplChars(configList[index]?.entityType)}
                        </a>
                      </Box>
                    )}
                  </div>
                </div>
                {entitlement[entitlementType] ===
                ENTITLEMENT_STATUS.noAccess ? null : (
                  <Link href={item.href}>
                    <Image
                      className="edit-icon"
                      src={IconEdit}
                      alt="Edit icon"
                    />
                  </Link>
                )}
              </CardContainer>
              {/* <CardContainer customClass="progress-item">
                {configList[index]?.inProgressData?.length &&
                  configList[index]?.inProgressData.map(
                    (data: any, listIndex: number) => (
                      <div className="content" key={listIndex}>
                        {data.name}
                      </div>
                    )
                  )}
              </CardContainer> */}
              {completedItems && index !== completedItems?.length - 1 && (
                <Divider className="solid-border" orientation="vertical" />
              )}
            </Box>
          ))}
        {inProgressItems && progressIndex !== 1 && (
          <Flex
            flexDirection={"row"}
            alignItems={"flex-end"}
            className="dashed-border-setup"
          >
            <div>
              <Divider className="dashed-border" orientation="vertical" />

              <Image
                className="down-icon"
                src={expandDownIcon}
                alt="expand down icon"
              />
            </div>
            <Text className="jump-back">JUMP BACK IN</Text>
          </Flex>
        )}
        {inProgressItems && (
          <CardContainer customClass="progress-item in-progress">
            <div className="content">
              <Image
                className="check-img"
                src={rightRotateYellow}
                alt="check"
              />
              <div className="text-content">
                <Text as={"h4"}>{inProgressItems?.title}</Text>
                <Text>{inProgressItems?.text}</Text>
                <Box width={"136px"} mt={4}>
                  <Badges
                    size="sm"
                    variant="subtle"
                    colorScheme="yellow"
                    label="In-progress"
                    type="warning"
                  />
                </Box>
              </div>
            </div>
            <Button
              onClick={onResume}
              className="app-btn-reg-secondary resume-button"
              isDisabled={
                entitlement[entitlementType] === ENTITLEMENT_STATUS.noAccess
              }
            >
              Resume <ChevronRightIcon w={10} h={10} />
            </Button>
          </CardContainer>
        )}
        {notCompletedItems &&
          notCompletedItems?.length > 0 &&
          notCompletedItems.map((item, index) => (
            <Box key={index} marginLeft={4} marginRight={30}>
              <CardContainer customClass="progress-item inactive">
                <div className="content">
                  <Image
                    className="check-img"
                    src={activeInterstial}
                    alt="check"
                  />
                  <div className="text-content">
                    <Text as={"h4"}>{item.title}</Text>
                    <Text>{item.text}</Text>
                  </div>
                </div>
              </CardContainer>
            </Box>
          ))}
      </CardContainer>
    </>
  );
};

export default InProgress;
